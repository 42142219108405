/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'); */

body{
  overflow: hidden;
}

#root {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

span {
  margin: 0;
  padding: 0;
}

p{
  margin: 5px 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif; 
}

h1,h2,h3,h4,h5,h6{
  margin: 5px 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif; 
}

/* ------------------------------------ FLEX ----------------------- */

.flex{
  display: flex;
}

.flex-center{
  display: flex;
  justify-content: center;
}

.flex-start{
  display: flex;
  justify-content: flex-start;
}
.flex-end{
  display: flex;
  justify-content: flex-end;
}

.flex-between{
  display: flex;
  justify-content: space-between;
}

.flex-around{
  display: flex;
  justify-content: space-around;
}

.align-center{
  align-items: center;
}

.align-start{
  align-items: start;
}

.align-end{
  align-items: end;
}

.flex-wrap{
  flex-wrap: wrap;
}
.row{
  -ms-flex-direction: row;
  flex-direction: row;
}
.column{
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* ------------------------------------ Overflow ----------------------- */
.overflow-hidden{
  overflow: hidden;
}

.overflow-ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ------------------------------------ Border ----------------------- */
.border-small{
  border-radius: 4px;
  border: 1px #ccc solid;
}

/* ------------------------------------ text ----------------------- */
.text-hover:hover{
  opacity: 0.7;
}

.sub-header{
  color:#999
}

.small-text{
  color:#8e8e8e;
  font-size: 12px;
}

/* ------------------------------------ width ----------------------- */

.w-100{
  width: 100%;
}

.w-50{
  width: 50%;
}

/* ------------------------------------ color ----------------------- */
.bg-w{
  background-color: white;
}
.bg-primary{
  background-color: white;
}

/* ------------------------------------ Margin ----------------------- */
.m-auto{margin: auto;}
.m-b-10{margin-bottom: 10px;}
.m-t-10{margin-top: 10px;}
.m-r-10{margin-right: 10px;}
.m-l-10{margin-left: 10px;}
.m-h-10{margin: 0 10px;}
.m-v-10{margin: 10px 0;}

.m-b-5{margin-bottom: 5px;}
.m-t-5{margin-top: 5px;}
.m-r-5{margin-right: 5px;}
.m-l-5{margin-left: 5px;}
.m-h-5{margin: 0 5px;}
.m-v-5{margin: 5px 0;}


.popup-overlay{
  background-color: rgba(0,0,0,.5);
}

.disable-link{
  pointer-events: none;
}

.simple-hover:hover{
  cursor: pointer;
  opacity: 0.6;
}